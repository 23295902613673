import _ from "lodash"
import {camelToSnake} from "./utils"

const llmAvailableProvidersToken = "llmAvailableProvidersToken"

export type LlmProvider = {
    title: string
    key: string
    name: string
}

export const llmAvailableProviders: LlmProvider[] = [
    {title: "OpenAI", key: "sk-proj-DNcpDTRRglYkrZGOO78dT3BlbkFJDOYGHHxAs7MrSlgHFM4Y", name: "OPENAI_API_KEY"},
    {title: "Mistral AI", key: "7YrCLL5JjoJ1HHrrHw6S6xbdvoqLq5o1", name: "MISTRAL_API_KEY"},
    {title: "Cohere", key: "", name: "COHERE_API_KEY"},
    {title: "Anthropic", key: "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", name: "ANTHROPIC_API_KEY"},
    {title: "Anyscale", key: "", name: "ANYSCALE_API_KEY"},
    {title: "Perplexity AI", key: "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", name: "PERPLEXITYAI_API_KEY"},
    {title: "DeepInfra", key: "", name: "DEEPINFRA_API_KEY"},
    {title: "TogetherAI", key: "9f6331e270a104b90d415748e20d40d0f57835b0190e40322a426ec7777b44dc", name: "TOGETHERAI_API_KEY"},
    {title: "Aleph Alpha", key: "", name: "ALEPHALPHA_API_KEY"},
    {title: "OpenRouter", key: "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", name: "OPENROUTER_API_KEY"},
    {title: "Groq", key: "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", name: "GROQ_API_KEY"},
    {title: "Gemini", key: "AIzaSyD8W_y5AHQhsjoXn99fpLAXY1yF9jXc1nA", name: "GEMINI_API_KEY"},
]

export const getApikeys = () => {
    if (typeof window !== "undefined") {
        const llmAvailableProvidersTokenString = localStorage.getItem(llmAvailableProvidersToken)
        const apiKeys: Array<LlmProvider> = []

        if (llmAvailableProvidersTokenString !== null) {
            const llmAvailableProvidersTokenArray = JSON.parse(llmAvailableProvidersTokenString)

            if (
                Array.isArray(llmAvailableProvidersTokenArray) &&
                llmAvailableProvidersTokenArray.length > 0
            ) {
                for (let i = 0; i < llmAvailableProvidersTokenArray.length; i++) {
                    if (llmAvailableProvidersTokenArray[i].key !== "") {
                        apiKeys.push(llmAvailableProvidersTokenArray[i])
                    }
                }
            }
        }
        return apiKeys
    }
}

export const saveLlmProviderKey = (providerName: string, keyValue: string) => {
    // TODO: add encryption here
    const keys = getAllProviderLlmKeys()
    const item = keys.find((item: LlmProvider) => item.title === providerName)
    if (item) item.key = keyValue
    localStorage.setItem(llmAvailableProvidersToken, JSON.stringify(keys))
}

export const getLlmProviderKey = (providerName: string) =>
    getAllProviderLlmKeys().find((item: LlmProvider) => item.title === providerName)?.key

export const getAllProviderLlmKeys = () => {
    const providers = _.cloneDeep(llmAvailableProviders)
    try {
        if (typeof window !== "undefined") {
            const providersInStorage: LlmProvider[] = JSON.parse(
                localStorage.getItem(llmAvailableProvidersToken) || "[{}]",
            )
            for (const provider of providers) {
                provider.key = providersInStorage.find((p) => p.title === provider.title)?.key || ""
            }
        }
    } catch (error) {
        console.log(error)
    }
    return providers
}

export const removeSingleLlmProviderKey = (providerName: string) => {
    const keys = getAllProviderLlmKeys()
    const item = keys.find((item: LlmProvider) => item.title === providerName)
    if (item) item.key = ""
    localStorage.setItem(llmAvailableProvidersToken, JSON.stringify(keys))
}

export const removeLlmProviderKey = () => {
    if (typeof window !== "undefined") {
        localStorage.removeItem(llmAvailableProvidersToken)
    }
}

export const setAllProviderKeys = (keys: { [key: string]: string }) => {
    const updatedProviders = _.cloneDeep(llmAvailableProviders);
    for (const provider of updatedProviders) {
        if (keys[provider.title]) {
            provider.key = keys[provider.title];
        }
    }
    if (typeof window !== "undefined") {
        localStorage.setItem(llmAvailableProvidersToken, JSON.stringify(updatedProviders));
    }
};
